import React from 'react';
import './assets/Amenities.scss';

export const AmenitiesSection: React.FC = () => {

    return (
        <section className="section-amenities">
            <div className="container">
                <h2 className="heading"><span className="emphasized">Amenities</span></h2>
                <div className="section-amenities-content">
                    <div>
                        <h3>Our Community</h3>
                        <ul>
                            <li>
                                Picturesque woods-lined backdrop on the desirable southeast side of town
                            </li>
                            <li>
                                Neighborhood Patio with landscaped garden boxes, relaxing sitting areas, & views of the woods
                            </li>
                            <li>
                                Mary E. Paul Room - Comfortable, rustic lodge-inspired community room with Free WiFi
                            </li>
                            <li>
                                Indoor controlled access bike & storage spaces
                            </li>
                            <li>
                                Resident designated garden plots
                            </li>
                            <li>
                                Free Off-street parking plus a limited number of covered parking spots
                            </li>
                            <li>
                                Free trash removal & on-site recycling
                            </li>

                            <li>
                                Water & sewer bill included in rent
                            </li>
                            <li>
                                Controlled access interior corridor entrances
                            </li>
                            <li>
                                Highly efficient all electric apartments with EnergyStar Rated appliances
                            </li>
                            <li>
                                Wired for <a href="https://bloomington.smithville.com/lakesneighborhood/">
                                Smithville Gigabit Internet</a>
                            </li>
                            <li>
                                Dogs (on first level) & Cats welcome
                            </li>
                            <li>
                                Managed by <a href="http://renaissancerentals.com/">
                                Renaissance Rentals </a>
                                Bloomington's most trusted local landlord
                            </li>
                            <li>
                                24 hour emergency maintenance
                            </li>
                        </ul>
                        <div className="main">
                            <h3>Select units include</h3>
                            <ul>
                                <li>Walk-in closet</li>
                                <li>Bonus room with closet &amp; ceiling fan, has vinyl plank floors</li>
                                <li>Entry coat closet</li>
                                <li>Private patio</li>
                                <li>Private balcony</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h3>Our Apartments</h3>
                        <ul>
                            <li>
                                Thoughtfully designed 1 &amp; 2 bedroom apartments flat
                            </li>
                            <li>
                                Large windows throughout
                            </li>
                            <li>
                                Tall 9' or 10' Ceilings
                            </li>
                            <li>
                                Vinyl plank floors in living room, kitchen
                            </li>
                            <li>
                                Tile floors in bathrooms
                            </li>
                            <li>
                                Carpet in bedrooms
                            </li>
                            <li>
                                Ceiling fan with light kit in bedrooms &amp; living room
                            </li>
                            <li>
                                Large bedroom closets
                            </li>
                            <li>
                                European villa-inspired kitchens with a mix of open-shelving and
                                traditional upper wall cabinets
                            </li>
                            <li>
                                Storage Pantry in kitchen
                            </li>
                            <li>
                                Sleek, smooth finish black kitchen appliances
                            </li>
                            <li>
                                Custom kitchen island with limestone counter top &amp; built-in
                                microwave
                            </li>
                            <li>
                                Creative in-unit storage solutions
                            </li>
                            <li>
                                Laundry room with Front Load HE Washer/Dryer
                            </li>
                            <li>
                                48" custom vanity in bathroom
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};
