import React from 'react';
import './assets/HeroSection.scss';
import {Video} from "@renaissancerentals/renaissance-component";
import {Button} from "@contentmunch/muncher-ui";

export const HeroSection: React.FC<HeroSectionProps> = ({handleRefToFloorPlan}) => {
    return (
        <div className="container">
            <section className="section-hero">
                <Video url="https://www.googleapis.com/drive/v3/files/1_xNbnw0UM2FF3Jl5FLLWjAtcL1vGLtXE?alt=media&key=AIzaSyAdG4u5YD2CZvQTv_hRtaKrmSNWZkY30oU"/>
                <div className="hero-content">
                    <h2>
                        be connected & inspired
                    </h2>
                    <h2>
                        <i>next to nature</i>
                    </h2>
                    <p>Spacious & Thoughtfully Designed<br/>1 & 2 b-Bedroom Apartments<br/>for Rent</p>

                    <Button variant={"primary"} onClick={handleRefToFloorPlan}>Find Your highgrove flat ››</Button>
                </div>
            </section>
        </div>
    );
}

export interface HeroSectionProps {
    handleRefToFloorPlan: () => void;
}
