import React from 'react';
import './assets/FeatureSection.scss';

export const FeatureSection:React.FC=()=> {
    return (
        <div className="container main">
            <section className="custom-feature">
                <div className="custom-feature--detail">
                    <h2>The Heart of HighGrove</h2>
                    <p> The enjoyment of the great outdoors is at the heart of HighGrove’s
                        vision. With onsite trail access,
                        close proximity to the State Parks, and community kayaks available, you can be
                        connected and
                        inspired next to nature.
                    </p>
                </div>
            </section>
        </div>
    );
}